<!--虚拟券包管理-->
<template>
    <div class="replaceRecharge">
        <div class="table-search">
            <div class="search-L">
                <el-select v-model="tableParams.memberId" placeholder="输入手机号搜索" filterable remote clearable
                    :remote-method="remoteMethod" :loading="loading" reserve-keyword @change="reload" class="mr15">
                    <div style="min-height: 180px">
                        <el-option v-for="(item, index) in userList" :key="index"
                            :label="item.nickName + ' | ' + item.userMobile" :value="item.memberId">
                        </el-option>
                    </div>
                    <div class="custom-pagination">
                        <el-pagination background layout="prev, pager, next" :page-size="userParams.pageSize"
                            :total="userTotal" @current-change="handlePageChange" :current-page.sync="currentPage">
                        </el-pagination>
                    </div>
                </el-select>
                <el-select v-model="tableParams.rechargeStatus" placeholder="请选择代充状态" clearable @change="reload"
                    class="mr15">
                    <el-option v-for="item in ObjToOpt(rechargeStatus)" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </div>

            <div class="search-R">
                <el-button type="primary" @click="onAdd()">发起代充</el-button>
            </div>
        </div>
        <el-table :data="tableData" border stripe :header-cell-style="{
            background: '#FAFAFA',
            color: '#000000',
        }">
            <el-table-column label="充值时间" align="center" show-overflow-tooltip prop="addDt" />
            <el-table-column label="代充用户" align="center" show-overflow-tooltip prop="nickName">
                <template slot-scope="scope">
                   <span>{{ scope.row.nickName + ' | ' + scope.row.mobile}}</span>
                </template>
            </el-table-column>
            <el-table-column label="代充本金" align="center" show-overflow-tooltip prop="rechargeAmount">
                <template slot-scope="scope">
                    <span>{{ '￥' + scope.row.rechargeAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column label="代充赠金" align="center" show-overflow-tooltip prop="rechargeGiveAmount">
                <template slot-scope="scope">
                    <span>{{ scope.row.rechargeGiveAmount ? '￥' + scope.row.rechargeGiveAmount : '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="累计成长值" align="center" show-overflow-tooltip prop="rechargeAmount">
                <template slot-scope="scope">
                    <span>{{ scope.row.experienceEnable ? '是' : '否' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="代充理由" align="center" show-overflow-tooltip prop="reason" />
            <el-table-column label="操作人" align="center" show-overflow-tooltip prop="optUserName" />
            <el-table-column label="代充状态" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{ rechargeStatus[scope.row.rechargeStatus] }}</span>
                </template>
            </el-table-column>
            <el-table-column width="200" label="操作" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="medium" @click.stop="goPay(scope.row)"
                        v-if="scope.row.rechargeStatus == 0">去付款</el-button>
                    <el-button type="text" size="medium" @click="goConfirmDeleteCard(scope.row)"
                        v-if="scope.row.rechargeStatus != 1">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 发起代充 -->
        <el-dialog :visible.sync="editShow" :title="'发起代充'" :close-on-click-modal="false" width="30%"
            @close="closeEditDialog('form-ref', 1)" center>
            <el-form :model="formData" :rules="rules" ref="form-ref" label-width="120px">
                <el-form-item label="代充用户" prop="memberId">
                    <el-select v-model="formData.memberId" placeholder="搜索手机号选择用户" filterable remote
                        :remote-method="remoteMethod" :loading="loading" reserve-keyword>
                        <div style="min-height: 180px">
                            <el-option v-for="(item, index) in userList" :key="index"
                                :label="item.nickName + ' | ' + item.userMobile" :value="item.memberId">
                            </el-option>
                        </div>
                        <div class="custom-pagination">
                            <el-pagination background layout="prev, pager, next" :page-size="userParams.pageSize"
                                :total="userTotal" @current-change="handlePageChange" :current-page.sync="currentPage">
                            </el-pagination>
                        </div>
                    </el-select>
                </el-form-item>
                <el-form-item label="代充本金" prop="rechargeAmount">
                    <el-input v-model="formData.rechargeAmount" type="number" :step="1" placeholder="输入代充金额"
                        @keydown.native="(e) =>
                        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                            ? false
                            : e.returnValue)
                            " @input="inputAmount($event,1)"></el-input>
                </el-form-item>
                <el-form-item label="代充赠金" prop="rechargeGiveAmount">
                    <el-input v-model="formData.rechargeGiveAmount" type="number" :step="1" placeholder="输入代充金额"
                        @keydown.native="(e) =>
                        (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
                            ? false
                            : e.returnValue)
                            " @input="inputAmount($event,2)"></el-input>
                </el-form-item>
                <el-form-item label="累计成长值" prop="experienceEnable">
                    <el-switch v-model="formData.experienceEnable" active-color="#EF9807" inactive-color="#909399">
                    </el-switch>
                </el-form-item>
                <el-form-item label="代充理由" label-width="120px" prop="reason">
                    <el-input v-model="formData.reason" autocomplete="off" placeholder="输入 200 字以内内容" maxlength="200"
                        show-word-limit type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSubmit('form-ref')">确定代充</el-button>
            </div>
        </el-dialog>
        <!-- 去付款 -->
        <el-dialog :visible.sync="payDialog" :title="'发起代充'" :close-on-click-modal="false" width="30%"
            @close="closeEditDialog('goPay-form-ref', 2)" center>
            <div style="width: 100%;text-align: center;">
                <vue-qr :logoSrc="imageUrl" :text="url" :size="200"></vue-qr>
            </div>
            <div slot="footer" class="dialog-footer">
                使用支付宝App扫码支付
            </div>
        </el-dialog>
        <div class="table-footer">
            <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
                :page-size="tableParams.pageSize" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="currentPage" @current-change="onCurrentPage">
            </el-pagination>
        </div>
    </div>
</template>
<script>

import { onProxy, proxyList, delProxy, getPayStatus } from "@/api/recharge/recharge.js";
import { likeUserList } from "@/api/user/user";
import vueQr from 'vue-qr'
import { rechargeStatus } from "@/db/objs.js";
import { ObjToOpt } from "@/utils/utils";


export default {
    components: {
        vueQr
    },

    data() {
        return {
            ObjToOpt,
            rechargeStatus,
            currentPage: 1,
            tableData: [],
            total: 0,
            tableParams: {
                kw: "",
                page: 1,
                pageSize: 10,
                rechargeStatus: "",
                merchantId: 0,
                shopId: 0,
            },
            formData: {},
            editShow: false,
            payDialog: false,
            userParams: {
                page: 1,
                pageSize: 10,
                kw: "",//查询关键字
                merchantId: "",//商户ID
                shopId: "",//店铺ID
            },
            imageUrl: require("@/assets/images/zfb.png"), // icon路径
            userList: [],
            loading: false,
            rules: {
                memberId: [{ required: true, message: '请选择代充用户' }],
                rechargeAmount: [

                ],
                reason: [{
                    required: true,
                    message: "请输入代充理由",
                    trigger: "blur",
                }],

            },
            url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
            userTotal: 0,
            currentPage: 1,
            timer: null,
        };
    },
    created() {
        this.init();
        this.getUserList()

    },
    mounted() {
        this.getTableList();
    },
    methods: {
        init() {
            let merchantShopId = window.localStorage.getItem('playOne-store-merchantShopId');
            if (merchantShopId) {
                this.userParams.merchantId = this.tableParams.merchantId = this.formData.merchantId = JSON.parse(merchantShopId)[0];
                this.userParams.shopId = this.tableParams.shopId = this.formData.shopId = JSON.parse(merchantShopId)[1];
            }
        },

        reset() {
            this.formData = {}
            this.init()
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.userParams.page = page;
            this.getUserList(1);
        },
        remoteMethod: _.debounce(function (query) {
            if (query !== '') {
                this.loading = true;
                this.userParams.kw = query;
                this.getUserList(1)
            } else {
                this.userList = [];
            }
        }, 500),
        // 【请求】表格数据
        getUserList(type) {
            likeUserList(this.userParams).then((res) => {
                if (res.isSuccess == "yes") {
                    if (type == 1) {
                        this.loading = false
                    }
                    this.userTotal = res.data.total;
                    this.userList = res.data.list;
                }
            });
        },
        inputAmount(value, type) {
            if (type == 1) {
                if (Number(value) == 0) {
                    this.formData.rechargeAmount = ''
                    this.$message.error('代充本金需为正整数')
                }
                if (value.length > 8) { this.formData.rechargeAmount = value.slice(0, 8) }
            } else {
                if (Number(value) == 0) {
                    this.formData.rechargeGiveAmount = ''
                    this.$message.error('代充赠金需为正整数')
                }
                if (value.length > 8) { this.formData.rechargeGiveAmount = value.slice(0, 8) }
            }

        },
        // 查询列表
        getTableList() {
            proxyList(this.tableParams).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableData = res.data.list;
                    this.total = res.data.total;
                }
            });
        },

        // 【监听】表格条数点击
        onSizeChange(size) {
            this.tableParams.pageSize = size;
            this.reload();
        },
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.tableParams.page = page;
            this.getTableList();
        },
        // 表格重载
        reload() {
            this.tableParams.page = 1;
            this.currentPage = 1;
            this.getTableList();
        },
        // 删除卡券
        goDeleteCard(proxyRechargeId) {
            delProxy({ proxyRechargeId: proxyRechargeId }).then((res) => {
                if (res.isSuccess == "yes") {
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    this.getTableList();
                }
            });
        },
        // 二次确认删除卡券
        goConfirmDeleteCard(row) {
            this.$confirm("是否删除该代充记录?", "删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // 接口
                    this.goDeleteCard(row.proxyRechargeId);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 发起代充
        onAdd() {
            this.editShow = true;
        },
        onSubmit: _.debounce(function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.formData.rechargeAmount == null && this.formData.rechargeGiveAmount == null) {
                        this.$message.error("请输入代充本金或者代充赠金");
                        return
                    }
                    onProxy(this.formData).then(res => {
                        if (res.isSuccess == "yes") {
                            this.editShow = false;
                            if (res.data.needPay) {
                                this.url = res.data.body
                                this.payDialog = true
                                this.timer = setInterval(() => {
                                    this.getPayStatus(res.data.proxyRechargeId)
                                }, 1000)
                            }else{
                                this.$message.success("发起代充成功")
                                this.getTableList()
                            }

                        } else {
                            this.$message.error(res.message);
                        }
                    })
                } else {
                    return false;
                }
            });
        }, 800),
        closeEditDialog(formName, type) {
            this.getTableList()
            if (type == 1) {
                this.reset()
                this.$nextTick(() => {
                    this.$refs[formName].clearValidate();
                })
            } else {
                clearInterval(this.timer)
            }
        },

        // 点击去付款
        goPay(row) {
            this.payDialog = true
            this.url = row.payBo
            this.timer = setInterval(() => {
                this.getPayStatus(row.proxyRechargeId)
            }, 1000)
        },
        // 获取支付状态
        getPayStatus(proxyRechargeId) {
            console.log('获取支付状态中')
            getPayStatus({ proxyRechargeId: proxyRechargeId }).then(res => {
                if (res.isSuccess == "yes") {
                    // 0未支付 1已支付 2支付失败
                    if (res.data == 1) {
                        this.$message.success("支付成功");
                        clearInterval(this.timer)
                        this.payDialog = false;
                        this.getTableList();
                    } else if (res.data == 2) {
                        this.$message.success("支付失败");
                        clearInterval(this.timer)
                        this.payDialog = false;
                        this.getTableList();
                    } else {

                    }

                }
            })
        },

    },
};
</script>
<style lang="scss" scoped>
.replaceRecharge {
    .pic {
        width: 160px;
        height: 160px;
        margin: 0 auto;

    }

    /deep/.el-dialog--center .el-dialog__body {
        padding-bottom: 0;
    }

    padding: 0.15rem;
    box-sizing: border-box;

    .table-search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.2rem;

        .search-L {
            display: flex;
        }
    }

    .table-footer {
        margin-top: 0.1rem;
        display: flex;
        justify-content: flex-end;
    }
}
</style>